import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FelsparkeradLogo from "../assets/felsparkerad.svg";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Daddy Longlegs" />
      <div className="flex flex-col w-full h-screen justify-center items-center">
        <h1 className="text-xl tracking-tight text-white absolute top-20">Daddy Longlegs</h1>
        <a href="https://felsparkerad.se" class="w-60 bg-pink pt-4 pb-5 px-5 rounded-lg"><FelsparkeradLogo /></a>
        <div class="absolute bottom-8 md:bottom-12 flex flex-col md:flex-row justify-center items-center text-white"><a href="mailto:hello@daddylonglegs.se" class="text-gray-400 hover:text-white pt-4 px-4">Contact us</a></div>
      </div>
    </Layout>
  )
}

export default IndexPage
